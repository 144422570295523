<template>
      <v-card width="100%">
        <v-card-title>Primer monitoreo del plan</v-card-title>
        <v-card-text>
          <v-row>
            <v-col cols="12" xs="12" sm="4" md="4">
              <v-select
                :value="this.$store.state.conciencia.conciencia_primermonitoreo"
                :item-value="valorcombo"
                :items="itemsOpciones"
                label="ESTATUS"
                dense
                filled
                @change="asignarValor($event)"
              >
              </v-select>
            </v-col>
            <v-col cols="12" xs="12" sm="8" md="8">
             <!-- aqui va fileupload -->

                <uploadFile5 v-if="verCombo"
                   :archivoId ="this.$store.state.conciencia.conciencia_primermonitoreo_docto"
                   action_a_Ejecutar ="action_conciencia_primermonitoreo_docto"      
               
                   >               
                </uploadFile5> 

            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
</template>

<script >


export default {

      name: 'PrimerMonitoreo', 

      props :{ 

          valorcombo       :  {  type       : String  , default : ''    },
          incidenteId      :  {  type       : String  , default : '0'   },
          archivoId        :  {  type       : String  , default : '0'   },
          //action_plan      :  {  type       : Boolean , default : false },
         // nombreDelArchivo :  {  type       : String  , default : 'Sin asignar'},
        //  sihayarchivo     :  {  type       : Boolean , default : false},

      },

      components : {
           
           uploadFile5: () => import('@/components/manipulacionArchivos/uploadFile5.vue')

      },

      data() {

          return {
               itemsOpciones: ["SI", "PENDIENTE", "NO APLICA"],
               verCombo : true
          }
      },

      methods : {
         asignarValor(event){

             event == "SI"   ? this.verCombo  = true : this.verCombo = false;
             this.valorCombo = this.verCombo;
            this.$store.dispatch("action_conciencia_primermonitoreo", event);
         }
      }

    }

</script>

<style scoped>

</style>