<template>

<v-container name="concienciacontainer">
<h2></h2>

    <statusConciencia></statusConciencia>
   <!-- <componentecardclasificacionplan></componentecardclasificacionplan>
   --> <br />
   <planAnualProteccion></planAnualProteccion>
   
   <br>
   <primerMonitoreo></primerMonitoreo><br>
   <segundoMonitoreo></segundoMonitoreo><br>
   <carpetaEvidencias></carpetaEvidencias><br>
   <!--  <componenteDocumentoConciencia></componenteDocumentoConciencia>
 <DocuementoConciencia
       :archivoId           ="this.$store.state.conciencia.conciencia_docto"
       :archivo             ="this.$store.state.conciencia.conciencia_docto" 
       :sihayarchivo        ="hayPlan"
       incidenteId         ="0"
       :nombreDelArchivo    ="nombreDelArchivo">

      </DocuementoConciencia>
    -->
<!-- 
        :objetoDatosArchivo  ="datosDelArchivo"
-->
       <br>
           <v-alert v-if="verAlerta" type="success">
             Este registro ha sido guardado
          </v-alert>
        <br>
      <v-row>

     
        <v-col>
            <v-btn block color="red" dark @click="close">Cancelar </v-btn>
        </v-col>
        <v-col>
            <v-btn block color="green" :loading="loading" dark @click="save">
            Guardar
            </v-btn>
        </v-col>
      </v-row>
      <br>
</v-container>

</template>

<script>
import eventBus from "@/eventBus.js";

import apiConciencia from '@/apialdeas/apiConciencia.js';
import planAnualProteccion from "@/components/componentesConciencia/planAnualProteccion.vue";
import primerMonitoreo from "@/components/componentesConciencia/primerMonitoreo.vue";
import segundoMonitoreo from "@/components/componentesConciencia/segundoMonitoreo.vue";
import carpetaEvidencias from "@/components/componentesConciencia/carpetaEvidencias.vue";
import statusConciencia from "@/components/componentesConciencia/statusConciencia.vue";
//import componentecardclasificacionplan from "@/components/componentesConciencia/componente-card-clasificacion-plan.vue";
//import DocuementoConciencia from "@/components/componentesConciencia/DocuementoConciencia.vue";
import validacionReporteInicial from   "@/components/etapas/validaciones/validacionReporteInicial.js";


export default {
  name: "agregarConcienciaComponente",



  components: {

    //componentecardclasificacionplan,
    statusConciencia,
     planAnualProteccion, 
     primerMonitoreo, 
     segundoMonitoreo, 
     carpetaEvidencias

  },

  mounted(){
   
   
   if (this.$store.state.conciencia.conciencia_id ==0 ){

    console.log(" iniciando conciencia ");

      //this.iniciaalizarVariables() ;

      this.inicializando_vuex_valores();
     
   }else {
     
     console.log("cargando el plan ");
     eventBus.$emit('cargarArchivoDocumentoConciencia');
     

   }

   


  },

  methods: {

    /* agregar en metodos del componente */
validacion_sePuedeCapturar(){

    this.errores = 0;  //debe de existir en data()

  const  { 
          
          
 
      conciencia_estatus , 
 
      //conciencia_clasificacion , 

    //  conciencia_docto,
     
     // conciencia_estatusplan,
 

     
         } = this.$store.state.conciencia;

 let r =  false ;



 r = validacionReporteInicial.existeInformacionParaCapturar(conciencia_estatus); 
   this.$store.dispatch('actions_uivars_error_conciencia_estatus',r);   
   this.validarCaptura(r);

/* r = validacionReporteInicial.existeInformacionParaCapturar(conciencia_clasificacion); 
   this.$store.dispatch('actions_uivars_error_conciencia_clasificacion',r);   
   this.validarCaptura(r);*/

 /*r = validacionReporteInicial.existeInformacionParaCapturar(conciencia_docto); 
   this.$store.dispatch('actions_uivars_error_conciencia_docto',r);   
   this.validarCaptura(r);*/

 /*r = validacionReporteInicial.existeInformacionParaCapturar(conciencia_estatusplan); 
   this.$store.dispatch('actions_uivars_error_conciencia_estatusplan',r);   
   this.validarCaptura(r);*/

  return this.errores;

  },

  /* agregar validarCaptura */
  validarCaptura(valor){

    let suma=0 ;
    valor== false ? suma=0 : suma=1;

    this.errores = this.errores + suma;

  },

        asignarValor_id(event){   
          
        this.$store.dispatch('action_conciencia_id',event );  
             
     },
    asignarValor_estatus(event){   
          
        this.$store.dispatch('action_conciencia_estatus',event );  
             
     },
    asignarValor_clasificacion(event){   
          
        this.$store.dispatch('action_conciencia_clasificacion',event );  
             
     },
    asignarValor_activo(event){   
          
        this.$store.dispatch('action_conciencia_activo',event );  
             
     },
    asignarValor_tipo(event){   
          
        this.$store.dispatch('action_conciencia_tipo',event );  
             
     },





    iniciaalizarVariables2(response){

    this.$store.dispatch('action_conciencia_id', response.data[0]['id']);
    this.$store.dispatch('action_conciencia_estatus', response.data[0]['estatus']);
   // this.$store.dispatch('action_conciencia_clasificacion', response.data[0]['clasificacion']);
    this.$store.dispatch('action_conciencia_activo', response.data[0]['activo']);
    this.$store.dispatch('action_conciencia_tipo', response.data[0]['tipo']);

    this.$store.dispatch('action_conciencia_docto', response.data[0]['docto']);
    this.$store.dispatch('action_conciencia_estatusplan', response.data[0]['estatusplan']);


    this.$store.dispatch('action_conciencia_activo', true);
      
    this.$store.dispatch('action_conciencia_primermonitoreo', response.data[0]['primermonitoreo']);
       this.$store.dispatch('action_conciencia_segundomonitoreo', response.data[0]['segundomonitoreo']);
       this.$store.dispatch('action_conciencia_primermonitoreo_docto', response.data[0]['primermonitoreo_docto']);
       this.$store.dispatch('action_conciencia_segundomonitoreo_docto', response.data[0]['segundomonitoreo_docto']);

       this.$store.dispatch('action_conciencia_carpeta', response.data[0]['_carpeta']);
       this.$store.dispatch('action_conciencia_carpeta_docto', response.data[0]['carpeta_docto']);
       this.$store.dispatch('action_conciencia_estado', response.data[0]['estado']);
       
},
    iniciaalizarVariables(){

  /*this.$store.dispatch('action_conciencia_id', response.data[0]['id']);
this.$store.dispatch('action_conciencia_estatus', response.data[0]['estatus']);
this.$store.dispatch('action_conciencia_clasificacion', response.data[0]['clasificacion']);
this.$store.dispatch('action_conciencia_activo', response.data[0]['activo']);
this.$store.dispatch('action_conciencia_tipo', response.data[0]['tipo']);


        this.$store.dispatch('action_conciencia_activo', true);*/
      

       
},

   inicializando_vuex_valores(){

      this.$store.dispatch('action_conciencia_id',0);
      this.$store.dispatch('action_conciencia_activo','');
     
      this.$store.dispatch('action_conciencia_estatus', '');
     // this.$store.dispatch('action_conciencia_clasificacion', '');
      this.$store.dispatch('action_conciencia_activo', '');
      this.$store.dispatch('action_conciencia_tipo', '');


      this.$store.dispatch('action_conciencia_docto', '0');
       this.$store.dispatch('action_conciencia_estatusplan', '');


       this.$store.dispatch('action_conciencia_primermonitoreo', '');
       this.$store.dispatch('action_conciencia_segundomonitoreo', '');

       this.$store.dispatch('action_conciencia_primermonitoreo_docto','0' );
       this.$store.dispatch('action_conciencia_segundomonitoreo_docto', '0');

       this.$store.dispatch('action_conciencia_carpeta', '');
       this.$store.dispatch('action_conciencia_carpeta_docto', '0');
       this.$store.dispatch('action_conciencia_estado', '');
     

    },

  
    close() {

      this.inicializando_vuex_valores();
      let t = "";

      this.$store.state.uivars.uivars_tipo_conciencia_o_prevencion =="Conciencia" ? t= 'c'
      : t='p';

      this.$router.push({ name : 'Conciencia' , params : { tipo : t }});
     },

    revisarErrores(){
      console.log("validando");
    },
    convertiValorCadenaANumero(valor){
      
      var valorInterno = valor;
      console.log("convirtiendo a number, obteniendo el tipo de de variable ");

      var tipoVar = typeof valorInterno;
      console.log(tipoVar);
      var valorNumerico = 0;
      try {

            if (tipoVar== "string"){
              console.log("este valor es un tipo cadena");
              console.log("convirtiendo a number");
              var intermedio = valorInterno.replace('"', '');
              valorNumerico = parseInt(intermedio);
            }else {
              valorNumerico = valorInterno ;
            }

      }catch(error){
        console.log("error en conversion de cadena a nunmero");
         console.log(error);
        valorNumerico=0;

      }

       return valorNumerico;
    },

    guardar_nuevoConciencia(){

          this.loading=true;
      
      let tipoagrabar = this.$store.state.uivars.uivars_tipo_conciencia_o_prevencion;

      var doctonumber = this.convertiValorCadenaANumero( this.$store.state.conciencia.conciencia_docto);
      var pmdocto =  this.convertiValorCadenaANumero(this.$store.state.conciencia.conciencia_primermonitoreo_docto);
      var smdocto =  this.convertiValorCadenaANumero(this.$store.state.conciencia.conciencia_segundomonitoreo_docto);
      var carpetanumber = this.convertiValorCadenaANumero(this.$store.state.conciencia.conciencia_carpeta_docto);

      let parametros = {
       
         id                     :  this.$store.state.conciencia.conciencia_id,
        estatus                 :  this.$store.state.conciencia.conciencia_estatus,
        clasificacion           :  'NO', //this.$store.state.conciencia.conciencia_clasificacion,
       //plan anual de proteccion infantil con cronograma
        docto                   : doctonumber,
        estatusplan             :  this.$store.state.conciencia.conciencia_estatusplan,
        
        primermonitoreo         : this.$store.state.conciencia.conciencia_primermonitoreo,
        primermonitoreo_docto   : pmdocto,
        segundomonitoreo        : this.$store.state.conciencia.conciencia_segundomonitoreo,
        segundomonitoreo_docto  : smdocto,
        carpeta                 : this.$store.state.conciencia.conciencia_carpeta,
        carpeta_docto           : carpetanumber,
        tipo                    : tipoagrabar,
        activo                  :  "1",
        estado                   : ''

      } ;
      console.log(" valor de conciencia : ");
      console.table( parametros );
  
      console.log(" cual es el valor del conciencia_activo : " +  this.$store.state.conciencia.conciencia_activo);
      console.log(" cual es el valor del this.ConcienciaID : " +   this.ConcienciaId);
  
    let promesa ;

    console.log( parametros);

    let idc =  this.$store.state.conciencia.conciencia_id;
    idc == 0 ? promesa = apiConciencia.nuevo__conciencia(parametros,this.$store) :
    promesa =apiConciencia.update__conciencia(parametros,this.$store) ;


     promesa
    .then( response => { 
         console.table(parametros);
      console.log(" valor de id conciencia: ");
      console.log(response.data["id"]);
      this.ConcienciaId = response.data["id"];
      this.$store.dispatch("action_conciencia_id",this.ConcienciaId);
      console.log(" Valor de ConcienciaId : " +  this.ConcienciaId);
      this.verAlerta = true;
      this.loading =false;

      } )
    .catch( error => { console.log(JSON.stringify(error.data))});

     this.loading= false;


 

    },// termina metodo 


    save() {
      
      
      this.validacion_sePuedeCapturar();
      
      this.errores>0 ? this.revisarErrores() : this.guardar_nuevoConciencia();
   
  },
  },
  data() {
    return {
      verAlerta : false,
      errores : false,
      hayPlan:false,
     // datosDelArchivo :
      tipoAMostrar : '',
      ConcienciaId  : '0',
      loading: false,
      itemsOpciones:["SI","NO"],
      emailRules: [ 
        v => !v || /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(v) || 'E-mail debe de ser valido'
      ]

    };
  },
};
</script>

<style scoped>
</style>