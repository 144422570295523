import { render, staticRenderFns } from "./primerMonitoreo.vue?vue&type=template&id=11256a1c&scoped=true&"
import script from "./primerMonitoreo.vue?vue&type=script&lang=js&"
export * from "./primerMonitoreo.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "11256a1c",
  null
  
)

export default component.exports